<template>
  <div class="backcolor" style="height: 100%">
    <v-row class="pa-8">
      <v-col cols="12" md="12" lg="8">
        <v-tabs-items :value="classTab">
            <v-card-text class="my-4">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="6"
                  v-for="(data, index) in classes"
                  :key="data.id"
                >
                  <v-alert
                    class="mx-2 pointer elevation-4 d-flex align-center"
                    :color="colorLookLight(index)"
                    text
                    outlined
                    :icon="
                      $vuetify.breakpoint.xsOnly
                        ? false
                        : 'mdi-book-open-page-variant'
                    "
                    :prominent="!$vuetify.breakpoint.xsOnly"
                    @click="seeClassDetails(data)"
                    min-height="100"
                  >
                    <v-row align="center" no-gutters>
                      <v-col
                        cols="12"
                        class="grow font-weight-bold black--text"
                      >
                      <div style="font-size: 1.1rem">
                        {{ data.Class.title }}
                        </div>
                      <div style="font-size: 0.8rem">
                        {{ data.Class.project }}
                      </div>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tabs-items>
      </v-col>
      <v-col v-if="quickacessverification" ls="12" md="12" lg="4" >
        <v-card-text class="my-4">
          <v-alert
            class="mx-2 pointer elevation-4 d-flex align-center"
            :color="colorLookLight(0)"
            text
            outlined
            :prominent="!$vuetify.breakpoint.xsOnly"
            min-height="100"
          >
            <div v-if="manuais.length" class="grow font-weight-bold black--text">
              {{ $t('AcessoRapido.suporte_tipos.suporte1') }}
            </div>
            <v-row align="center" no-gutters>
              <v-col
              v-for="data in manuais"
              :key="data.id"
              cols="12"
              class="grow font-weight-bold black--text"
              @click="link(data.url)"
              >
                <li>
                  {{ data.name }}
                </li>
              </v-col>
            </v-row>
            <br>
            <div v-if="treinamentos.length > 0" class="grow font-weight-bold black--text">
              {{ $t('AcessoRapido.suporte_tipos.suporte2') }}
            </div>
            <v-row align="center" no-gutters>
              <v-col
              v-for="data in treinamentos"
              :key="data.id"
              cols="12"
              class="grow font-weight-bold black--text"
              @click="link(data.url)"
              >
                <li>
                  {{ data.name }}
                </li>
              </v-col>
            </v-row>
            <br>
            <div v-if="suporte.length > 0" class="grow font-weight-bold black--text">
              {{ $t('AcessoRapido.suporte_tipos.suporte3') }}
            </div>
            <v-row align="center" no-gutters>
              <v-col
              v-for="data in suporte"
              :key="data.id"
              cols="12"
              class="grow font-weight-bold black--text"
              @click="link(data.url)"
              >
                <li>
                  {{ data.name }}
                </li>
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>
      </v-col>
    </v-row>
    <v-dialog
      v-model="classDetailsDialog"
      :max-width="$vuetify.breakpoint.smAndUp ? '60%' : '95%'"
    >
      <v-card>
        <v-tabs v-model="tab" background-color="darkblue" grow dark>
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="(n, i) in items" :key="i" :href="`#${n.id}`">
            <span> {{ n.title }} </span>
          </v-tab>
        </v-tabs>
        <v-btn
          rounded
          outlined
          @click="classDetailsDialog = false"
          color='white'
        >
          <v-icon class="mr-1" color="error">mdi-close-circle</v-icon>
        </v-btn>
        <v-tabs-items :value="tab">
          <v-tab-item value="1">
            <v-card class="px-4 py-6 py-sm-8 py-md-10 py-lg-16 d-flex flex-row">
              <div class="text-center">
                <v-avatar
                  :size="$vuetify.breakpoint.mdAndUp ? 260 : 200"
                  tile
                  class="rounded-xl mx-auto"
                >
                  <v-img :src="detailsData.tumbnail" />
                </v-avatar>
              </div>

              <div style="font-size: 1.2rem" class="mt-4 ml-4">
                <div style="font-size: 1.6rem" class="font-weight-black">
                  {{ detailsData.title }}
                </div>

                <div class="mt-4">
                  <strong>{{ $t('homework.homework_detalhes.detalhes1') }}</strong> {{ detailsData.project }}
                </div>
                <div><strong>{{ $t('homework.homework_detalhes.detalhes2') }}</strong> {{ detailsData.Kit.name }}</div>
                <div>
                  <strong>{{ $t('homework.homework_detalhes.detalhes3') }}</strong> {{ detailsData.description }}
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item value="2">
            <v-card class="pt-6 px-16 backcolor mx-auto" flat>
              <v-carousel
                v-model="videos"
                hide-delimiters
                :continuous="false"
                show-arrows-on-hover
                :width="$vuetify.breakpoint.xsOnly ? 320 : ''"
                align="center"
                height="380"
              >
                <v-carousel-item v-for="pres in presentation" :key="pres.id">
                  <vimeo-player
                    ref="player"
                    :video-url="`https://player.vimeo.com/video/${pres.uri}?h=${pres.hash_parameter}`"
                  />
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </v-tab-item>
          <v-tab-item value="3">
            <v-card class="pa-4">
              <v-carousel
                hide-delimiters
                show-arrows-on-hover
                :continuous="false"
                v-model="m_corousel"
              >
                <v-carousel-item
                  v-for="mount in mountFile"
                  :key="mount.id"
                  :src="mount.uri"
                  contain
                ></v-carousel-item>
              </v-carousel>
            </v-card>
          </v-tab-item>
          <v-tab-item value="4">
            <div class="text-h6 black--text mt-4 mx-6 font-weight-bold">
              {{ $t('homework.homework_exercise.exercise') }}:
            </div>
            <div v-for="(item, index) in exerc" :key="item.id" class="ma-4">
              <v-card class="rounded-lg pa-4" flat>
                <v-card-text class="px-2">
                  <div
                    class="text-subtitle-1 black--text font-weight-medium mb-2"
                  >
                    {{ index + 1 }}. {{ item.content }}
                  </div>
                  <div v-if="item.image != null">
                    <v-img
                      :src="item.image"
                      max-width="350px"
                      class="mx-auto mb-2"
                    />
                  </div>
                  <div
                    class="text-body-1"
                    :class="opt.correct ? 'green--text' : ''"
                    v-for="(opt, index) in item.option"
                    :key="opt.id"
                  >
                    <div v-if="item.type == 'M'">
                      {{ index + 1 }}- {{ opt.content }}
                      <v-icon v-if="opt.correct" small color="green"
                        >mdi-check</v-icon
                      >
                    </div>
                    <div v-if="item.type == 'I'">
                      {{ index + 1 }}.
                      <v-img
                        contain
                        :src="opt.content"
                        max-width="280px"
                        :class="opt.correct ? 'correctImg' : ''"
                        class="mb-2"
                      />
                    </div>
                    <div v-if="item.type == 'B'">
                      <span v-if="opt.correct" class="font-weight-bold">
                        R: {{ opt.content }}</span
                      >
                    </div>
                    <div v-if="item.type == 'D'">
                      <span v-if="opt.correct" class="font-weight-bold">
                        R: {{ opt.content }}</span
                      >
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          left
          fab
          absolute
          large
          color="darkcolor"
          class="elevation-8"
          style="bottom: 30px; right: 30px"
          v-bind="attrs"
          v-on="on"
          @click="returnMenu()"
        >
          <v-icon color="white">mdi-reply</v-icon>
        </v-btn>
      </template>
      <span> {{ $t('botoes.botao_voltar') }} </span>
    </v-tooltip>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  data() {
    return {
      dialogRegister: false,
      rules: {
        email: (v) => !!(v || "").match(/@/) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        none: true,
      },
      videos: 0,
      d_carousel: false,
      m_corousel: 0,
      mounting: [],
      classes: [],
      description: null,
      noDataClass: this.$t('homework.homework_kit'),
      quickacessverification: false,
      summary: null,
      classId: null,
      courses: [],
      classColors: [
        { light: "pink ", dark: "darkpink" },
        { light: "cyan", dark: "darkcyan" },
        { light: "yellow", dark: "darkyellow" },
      ],
      headers: [
        { text: this.$t('homework.homework_title'), value: "Class.title" },
        { text: "", value: "details", sortable: false, align: "end" },
      ],
      dialogSummarie: false,
      dialogExercises: false,
      summ: null,
      isSelecting: false,
      selectedFile: null,
      exerc: null,
      allExerc: null,
      mountFile: [],
      presentationFile: null,
      presentation: [],
      noSummaries: false,
      noExercises: false,
      classDetailsDialog: false,
      tab: null,
      classTab: null,
      items: [],
      hasPresentation: false,
      hasMounting: false,
      hasExercise: false,
      detailsData: { Kit: {} },
      videoID: null,
      files: {},
      manuais: [],
      treinamentos: [],
      suporte: [],
    };
  },

  async created() {
    this.getClassByCourses(localStorage.getItem("courseId"))
    await this.quickAccess(localStorage.getItem("courseId"))
    await this.teacherhelp(localStorage.getItem("classId"))
  },
  methods: {
    returnMenu() {
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu13');
    },
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      let selected = e.target.files[0];
      let formData = new FormData();
      formData.append("file", selected, selected.name);
      let res = await axios.post(`${url}/lessonsExtras/files`, formData);
      this.summ = res.data.url;
      let data = { classId: this.classId, uri: this.summ };
      axios.post(`${url}/summaries`, data, {
        headers: {
          authorization: token,
        },
      });
    },
    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
    colorLookDark(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].dark;
      return color;
    },

    async teacherhelp(id) {
      let m = await axios.get(`${url}/teacherSupport/${id}`, {
        headers: {
          authorization: token,
        },
      });
      console.log(m)
    },

    getClassByCourses(courseId) {
      axios
        .get(`${url}/courseAssembly/classesByCourses/${courseId}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          let classId = localStorage.getItem("classId")
          this.getCourseFiles(courseId);
          this.classTab = 1;
          this.videos = 0;
          for (let index = 0; index < res.data.length; index++) {
            if(res.data[index].fk_classId == classId){
              this.classes[0] = res.data[index];
              console.log(this.classes)
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getCourseFiles(id) {
      try {
        let res = await axios.get(`${url}/filesCourses/byCourse/${id}`, {
          headers: {
            authorization: token,
          },
        });
        this.files = res.data;
      } catch (error) {
        console.error(error);
      }
    },

    async getMounting(id) {
      let m = await axios.get(`${url}/lessonsMountings/byClass/${id}`, {
        headers: {
          authorization: token,
        },
      });
      if (m.data.length !== 0) {
        this.m_corousel = 0;
        this.mountFile = m.data;
        this.hasMounting = true;
      }

      let present = await axios.get(
        `${url}/lessonsPresentations/byClass/${id}`
      );

      if (present.data.length !== 0) {
        this.presentation = present.data;
        console.log("Videos", this.presentation);
        this.hasPresentation = true;
      } else {
        this.hasPresentation = false;
      }
    },

    async quickAccess(id) {
      let treinamentosindex = 0
      let manuaisindex = 0
      let suporteindex = 0
      let m = await axios.get(`${url}/quickAccess/${id}`, {
        headers: {
          authorization: token,
        },
      });
      if(m.data.length > 0){
        this.quickacessverification = true
      for (let index = 0; index < m.data.length; index++) {
        const quickacess =  m.data[index];
        if(quickacess.type == "T"){
          this.treinamentos[treinamentosindex] = quickacess
          treinamentosindex += 1
        }
        else if(quickacess.type == "U"){
          this.manuais[manuaisindex] = quickacess
          manuaisindex += 1
        }
        else if(quickacess.type == "S"){
          this.suporte[suporteindex] = quickacess
          suporteindex += 1
        }
          
      }
    }
    else{
      this.quickacessverification = false
    }
    },

    async seeExercises(id) {
      let res = await axios.get(`${url}/exercisesByClass?lessons=${id}`, {
        headers: {
          authorization: token,
        },
      });

      if (res.data.length != 0) {
        this.hasExercise = true;
        this.exerc = res.data;
      } else {
        this.hasExercise = false;
        this.exerc = [];
      }
    },
    toEdit(res) {
      let id = res[0].classId;
      this.$parent.$parent.$parent.exerciseId = id;
      this.$emit("message", "Exercícios");
      this.$parent.$parent.$parent.page = "Exercícios";
    },
    cleanDatas() {
      this.videos = 0;
      this.items = [];
      this.hasExercise = false;
      this.hasMounting = false;
      this.hasPresentation = false;
      this.detailsData = { Kit: {} };
    },
    async seeClassDetails(data) {
      console.log(data)
      localStorage.setItem("classId", data.fk_classId);
      // this.cleanDatas();

      // await this.getMounting(data.Class.id);
      // await this.seeExercises(data.Class.id);

      // this.verifyTab();

      // this.detailsData = data.Class;

      // this.classDetailsDialog = true;
    },

    async link(data) {
      window.open(data)
    },

    verifyTab() {
      this.items.push({
        id: 1,
        title: this.$t('homework.homework_tab.tab3'),
      });

      if (this.hasPresentation) {
        this.items.push({
          id: 2,
          title: this.$t('homework.homework_tab.tab4'),
        });
      }
      if (this.hasMounting) {
        this.items.push({
          id: 3,
          title: this.$t('homework.homework_tab.tab5'),
        });
      }
      if (this.hasExercise) {
        this.items.push({
          id: 4,
          title:  this.$t('homework.homework_tab.tab6'),
        });
      }
    },
  },
};
</script>

<style scoped>
.correctImg {
  border: 5px solid #468c00;
}

.pointer {
  cursor: pointer;
}
</style>
